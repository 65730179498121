<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        There is a general procedure for recrystallizing your unknown compound compound using
        ethanol - water as the solvent pair. Arrange the steps of the recrystallization procedure,
        from start to finish.
      </p>

      <drag-to-rank-input
        v-model="inputs.studentAnswers"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Start"
        append-text="Finish"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LB_A8Q14',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        'Dissolve the sample in a small amount of hot enthanol.',
        'Add hot water drop by drop until the solution gets a bit cloudy.',
        'Add hot ethanol dropwise until cloudiness disappears.',
        'Allow the solution to cool slowly to room temperature.',
        'Place the solution in an ice bath and leave for 10-15 min.',
        'Filter the crystals.',
        'Dry the crystals.',
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
